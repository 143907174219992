import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import api from "./api";
import { useOutletContext } from "react-router-dom";
// import { Base64 } from 'https://cdn.jsdelivr.net/npm/js-base64@3.7.5/base64.mjs';
import { Base64 } from 'js-base64';
import { useAuth } from "./useAuth";
import CadesUtils from "@avb56/cades-utils";
import './SignInCert.css';
import oConf from './conf';
import dictionary from "./dictionary";

const { sOmsUuid } = oConf[process.env.REACT_APP_TEST ? 'oDev' : 'oProd'];

let clientToken;

export function Test() {
  const { user } = useAuth();
  const errorHandler = er => console.log(er);
  // const errorHandler = er => alert(er);

  const [sOrderUuid, setOrderUuid] = useState('c54ac155-60cb-4cf6-b909-992bc21d3371');
  const [bLoading, setLoading] = useState();
  const sOmsUrlParams = '?omsId=' + sOmsUuid + '&orderId=' + sOrderUuid + '&gtin=';

  const fLoadCodes = () => api.getOms('/order/codes/blocks' + sOmsUrlParams).then(response => console.log(response));
  // const fLoadCodes = () => api.postOms('/utilisation?omsId=' + sOmsUuid, body)
  // .then(response => console.log(response));

  const handleClick = () => console.log(new Date(1703240623955).toISOString());

  return (
    <Box sx={{ m: 2 }}>
        <TextField
          label="Идентификатор заказа"
          size="small"
          value={sOrderUuid}
          onChange={ev => setOrderUuid(ev.target.value)}
          sx={{ width: 400, mr: 1 }}
        />
        <Button
          variant="contained"
          onClick={fLoadCodes}
          className={bLoading ? 'crpt-submit crpt-load' : 'crpt-submit'}
          disabled={bLoading}
          sx={{ width: 200 }}
        >
          {bLoading ? '' : 'Загрузить коды'}
        </Button>
        <Button variant="contained" onClick={handleClick} sx={{ display: 'none' }}>
          Click
        </Button>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import api from "./api";
import { useOutletContext } from "react-router-dom";
import './SignInCert.css';
import oConf from './conf';

const { sOmsUuid } = oConf[process.env.REACT_APP_TEST ? 'oDev' : 'oProd'];
const errorHandler = er => alert(er);
const errorLog = er => console.log(er);

export function Home() {
    const [sOrderUuid, setOrderUuid] = useState();
    const [bLoading, setLoading] = useState();
    const [bOrderValid, setOrderValid] = useState();
    const [oReservedCodes, setReservedCodes] = useState({});
    const [aAreas, setAreas] = useState([]);
    const [aWaitStatus, setWaitStatus] = useState(JSON.parse(localStorage.waitStatusChange || "[]"));
    const sOmsUrlParams = '?omsId=' + sOmsUuid + '&orderId=' + sOrderUuid;

    const fLoadCodes = () => setLoading(true) || api.getOms('/order/status' + sOmsUrlParams)
    .then(aOrderStatuses => {
        // console.log(aOrderStatuses);
        if (aOrderStatuses.find(o => o.bufferStatus == "PENDING")) {
            setTimeout(fLoadCodes, 1e3);
            return;
        }
        if (!aOrderStatuses.find(oOrder => oOrder.bufferStatus == "ACTIVE")) {
            alert('В указанном заказе нет доступных кодов (Исчерпан)');
            setLoading();
            return;
        }
        return Promise.all(aOrderStatuses.map(oOrder => oOrder.availableCodes > 0 && api.getOms(
            // '/codes'+sOmsUrlParams+'&gtin='+oOrder.gtin+'&quantity='+oOrder.availableCodes
            `/codes${sOmsUrlParams}&gtin=${oOrder.gtin}&quantity=${oOrder.availableCodes}`
        )))
        .then(aRes => aRes.reduce(
            (aCodes, oOrder) => oOrder ? aCodes.concat(oOrder.codes) : aCodes, []
        ))
        .then(aCodes => api.postDb('_bulk_docs', { docs: aCodes.map(code => {
            let [ _id, hash ] = code.split('\u001d');
            return { _id, hash }
        })}))
        .then(res => {
            if (res.filter(code => code.ok).length === res.length) {
                alert(res.length + ' код(ов) успешно загружены!');
                return;
            }
            if (res.filter(code => code.error === 'conflict').length === res.length) {
                throw new Error('Этот заказ уже был загружен.');
            }
            throw new Error(JSON.stringify(res, '', 2));
        }).finally(() => setLoading());
    }).catch(er => {
        if (er.globalErrors) {
            setLoading();
            alert(er.globalErrors[0].error);
            return;
        }
        return errorHandler(er);
    });
      
    const fOrderIdChange = ev => {
        const uOrderInput = ev.target.value;
        setOrderUuid(uOrderInput);
        setOrderValid(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(uOrderInput));
    }
      
    const fFlushWaites = ev => {
        delete localStorage.waitStatusChange;
        setWaitStatus([]);
    }

    useEffect(() => {
        api.getDb('areas').then(res => setAreas(res.list));
        api.getDb('reserved').then(res => setReservedCodes(res.list));
        aWaitStatus.length && api.post('/cises/info?pg=water', aWaitStatus.map(oItem => oItem.cis))
        .then(oInfo => {
            const aNewWaitStatuses = aWaitStatus.reduce((aNewWait, oItem, nIndex) => {
                if (oItem.status == oInfo[nIndex].cisInfo.status) aNewWait.push(oItem);
                return aNewWait;
            }, []);
            localStorage.waitStatusChange = JSON.stringify(aNewWaitStatuses);
            setWaitStatus(aNewWaitStatuses);
        });
    }, []);

    return <Box sx={{ m: 3 }}>
        <TextField
            label="Идентификатор заказа"
            size="small"
            value={sOrderUuid}
            onChange={fOrderIdChange}
            sx={{ width: 400, mr: 1 }}
            error={sOrderUuid && !bOrderValid}
            helperText={sOrderUuid && !bOrderValid && 'Введённая строка не соответствует формату UUID'}
        />
        <Button
            variant="contained"
            onClick={fLoadCodes}
            className={bLoading ? 'crpt-submit crpt-load' : 'crpt-submit'}
            disabled={bLoading || !bOrderValid}
            sx={{ width: 200 }}
        >
            {bLoading ? '' : 'Загрузить коды'}
        </Button>
        <Typography sx={{ pt: 3 }}>
            Зарезервированные на кассах коды:
        </Typography>
        <List>
            {Object.entries(oReservedCodes).map(([sAreaName, aCodes]) => <ListItem
                key={sAreaName}
            >
                <ListItemText primary={aAreas.length && aAreas[sAreaName.slice(4)].name + ': ' + aCodes.length} />
            </ListItem>)}
        </List>
        <Typography sx={{ pt: 3 }}>
            Ожидают изменения статуса: {aWaitStatus.length || 0}
        <Button
            variant="contained"
            onClick={fFlushWaites}
            className='crpt-submit'
            disabled={!aWaitStatus.length}
            sx={{ mx: 2 }}
        >
            Удалить (при ошибке)
        </Button>
        </Typography>
    </Box>
}

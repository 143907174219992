import React, { useState, useEffect } from "react";
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv8";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import dictionary from "./dictionary";

export default function CodesApplied(props) {
    const { selectionModel, inn, fActions, children } = props;
    const fActionsHandler = ev => fActions(ev.target, refForm.state.formData);
    const formData = {
        once: {
            production_date: new Date().toISOString().slice(0, 10),
            production_type: "OWN_PRODUCTION",
            participant_inn: inn,
            producer_inn: inn,
            owner_inn: inn
        },
        multi: {
            tnved_code: "2201101900",
            certificate_document_data: [{
                certificate_type: "CONFORMITY_DECLARATION",
                certificate_number: "ЕАЭС N RU Д-RU.РА06.В.22002/24",
                certificate_date: "2024-07-19",
                // well_number: "qwe123, asdf59, zxv321"
            }]
        }
    }

    let refForm;

    return <>
        <DialogTitle
            id="draggable-dialog-title"
            sx={{ pr: 8, cursor: 'move' }}
        >
            Ввод в оборот
        </DialogTitle>
        <DialogContent dividers>
            {children}
            <Form
                schema={schema}
                uiSchema={{
                    ...uiSchema,
                    "ui:submitButtonOptions": { "norender": true }
                }}
                formData={formData}
                ref={form => refForm = form}
                validator={validator}
                fields={fields}
                // onSubmit={ev => console.log(ev.formData)}
                templates={{ ButtonTemplates: { AddButton, RemoveButton } }}
                // sx={{
                //     width: 300,
                //     '& .MuiMenuItem-gutters': {
                //         whiteSpace: 'normal'
                //     },
                // }}
            />
        </DialogContent>
        <DialogActions sx={{ m: 1 }} onClick={fActionsHandler}>
            <Button variant="contained" name='printCodes' title={'Печать'} sx={{ mr: 2 }}>
                <PrintIcon />
            </Button>
            <FileDownloadIcon />{selectionModel.length} код(а/ов)
            <Button variant="contained" name='introduseFile' sx={{ mx: 1 }}>Файл</Button>
            <Button variant="contained" name='introduseSign'>Подписать и отправить</Button>
        </DialogActions>
    </>
}

const AddButton = props => <Button variant="contained" {...props}>
    <AddIcon sx={{ mr: 1 }} />Добавить {props.uiSchema.items.labelText || ''}
</Button>

const RemoveButton = props => <Button
    variant="contained" {...props}
    title={'Удалить ' + props.uiSchema.labelText || ''}
><DeleteIcon/></Button>

// function AddButton(props) {
//     console.log(props.uiSchema);
//     return (
//         <Button variant="contained" {...props}>
//             <AddIcon sx={{ mr: 1 }} />Добавить {props.uiSchema.labelText}
//         </Button>
//     );
// };

const tnvedTrash = 'АЛКОГОЛЬНЫЕ И БЕЗАЛКОГОЛЬНЫЕ НАПИТКИ И УКСУС. ВОДЫ, ВКЛЮЧАЯ ПРИРОДНЫЕ ИЛИ ИСКУССТВЕННЫЕ МИНЕРАЛЬНЫЕ, ГАЗИРОВАННЫЕ, БЕЗ ДОБАВЛЕНИЯ САХАРА ИЛИ ДРУГИХ ПОДСЛАЩИВАЮЩИХ ИЛИ ВКУСО-АРОМАТИЧЕСКИХ ВЕЩЕСТВ; ЛЕД И СНЕГ.';

const schema = {
    type: "object",
    properties: {
        once: {
            type: "object",
            properties: {
                production_date: { type: "string" },
                production_type: { type: "string" },
                participant_inn: { type: "string" },
                producer_inn: { type: "string" },
                owner_inn: { type: "string" }
            }
        },
        multi: {
            type: "object",
            title: '',
            properties: {
                tnved_code: {
                    type: "string",
                    title: "Код ТН ВЭД ЕАЭС товара",
                    "oneOf": [
                        {
                            "const": "2201101100",
                            "title": "2201101100 " + tnvedTrash + " - негазированные"
                        },
                        {
                            "const": "2201101900",
                            "title": "2201101900 " + tnvedTrash + " - прочие"
                        },
                        {
                            "const": "2201109000",
                            "title": "2201109000 " + tnvedTrash + " - прочие"
                        },
                        {
                            "const": "2201900000",
                            "title": "2201900000 " + tnvedTrash + " - прочие"
                        }
                    ]
                },
                certificate_document_data: {
                    type: "array",
                    title: 'Разрешительные документы',
                    items: {
                        type: "object",
                        properties: {
                            certificate_type: {
                                type: "string",
                                title: 'Вид документа',
                                "oneOf": Object.entries(dictionary.certificate_types)
                                .map(([key, title]) => ({ const: key, title }))
                            },
                            certificate_number: { type: "string", title: 'Номер документа' },
                            certificate_date: { type: "string", title: 'Дата документа' },
                            well_number: { type: "string" },
                        }
                    }
                },
                licences: {
                    type: "array",
                    title: 'Лицензии на пользование недрами',
                    items: {
                        type: "object",
                        properties: {
                            licence_number: {
                                type: "string",
                                title: 'Номер лицензии на пользование недрами'
                            },
                            licence_date: { type: "string", title: 'Дата выдачи лицензии' }
                        }
                    }
                }
            }
        }
    }
};

const uiSchema = {
    once: { "ui:field" : "base" },
    multi: {
        certificate_document_data: {
            "ui:options": { orderable: false },
            "ui:ArrayFieldTitleTemplate": props => <Typography>{props.title}</Typography>,
            items: {
                labelText: 'разрешительный документ',
                "ui:TitleFieldTemplate": 'hidden',
                certificate_date: { "ui:widget": "date" },
                well_number: {
                    "ui:widget": props => <Autocomplete
                        multiple
                        options={[]}
                        freeSolo
                        defaultValue={props.value?.split(', ')}
                        onChange={(ev, newValue) => props.onChange(newValue.join(', '))}
                        // onChange={ev => console.log(props.value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Номер(а) скважины"
                                placeholder="Укажите номер(а)"
                            />
                        )}
                    />
                }
            }
        },
        licences: {
            "ui:options": { orderable: false },
            "ui:ArrayFieldTitleTemplate": props => <Typography>{props.title}</Typography>,
            // "ui:ArrayFieldItemTemplate": props => <Typography>123</Typography>,
            items: {
                licence_date: { "ui:widget": "date" },
                "ui:TitleFieldTemplate": 'hidden',
                labelText: 'лицензию',
            }
        }
    }
};

const fields = { base: (props) => <Grid
        container spacing={3} alignItems="center" sx={{ mb: -3 }}
        onChange={ev => props.onChange({ ...props.formData, [ev.target.name]: ev.target.value })}
    >
        <Grid item xs={4}>
            <TextField
                type="date"
                label='Дата производства'
                name='production_date'
                fullWidth
                value={props.formData.production_date}
            />
        </Grid>
        <Grid item xs={8}>
            <TextField
                label='Тип производственного заказа'
                name='production_type'
                fullWidth
                select
                value={props.formData.production_type}
                // disabled
            >
                <MenuItem value="OWN_PRODUCTION">Собственное производство</MenuItem>
            </TextField>
        </Grid>
        <Grid item xs={6}>
            <TextField
                name='participant_inn'
                value={props.formData.participant_inn}
                sx={{ display: 'none' }}
            />
            <TextField
                label='ИНН производителя'
                name='producer_inn'
                fullWidth
                value={props.formData.producer_inn}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                label='ИНН собственника'
                name='owner_inn'
                fullWidth
                value={props.formData.owner_inn}
                // onClick={ev => console.log(props)}
            />
        </Grid>
    </Grid>
}

import CadesUtils from "@avb56/cades-utils";
import CadesPluginer from "@crpt/cades-pluginer";
import { Base64 } from 'js-base64';
import oConf from './conf';

let oSelectedCert, sOmsTokenUuid;

const sBaseUrlTest = 'https://cap.orenkontur.ru:49253/apitest';
// const sBaseUrl = 'http://localhost:8080/https://markirovka.sandbox.crptech.ru/api/v3/true-api';
// const sOmsBaseUrl = 'http://localhost:8080/https://suz.sandbox.crptech.ru/api/v3';
// const { sOmsConnUuid } = oConf[process.env.REACT_APP_TEST ? 'oDev' : 'oProd'];
// const sDbBaseUrl = 'http://192.168.7.125:49253/cdbtest/';

const {sBaseUrl, sBaseUrlV4, sOmsBaseUrl, sOmsConnUuid, sDbBaseUrl} = oConf[process.env.REACT_APP_TEST ? 'oDev' : 'oProd'];
const fConv2b64 = src => Base64.encode(typeof src == 'object' ? JSON.stringify(src) :  src);
const errorHandler = er => alert(er);
// const errorHandler = er => console.log(er);

const parseType = headers => headers.get('Content-Type').includes('/json') ? 'json' : 'text';
const readBodyResponse = response => response[parseType(response.headers)]()
.then(data => {
  if (response.ok) return data;
  else throw({ data, response });
});

const oHeaders = { 'Content-Type': 'application/json;charset=utf-8' }
const oOmsHeaders = { ...oHeaders, 'Accept': 'application/json' }
const oDbHeaders = { ...oHeaders, Authorization: 'Basic ' + btoa('marker:M@rker') }

const fFetch = (method, sUrl, body, headers = oHeaders) => fetch(sUrl, {
  method, headers,
  credentials: headers == oDbHeaders ? 'include' : undefined,
  body: body ? typeof body == 'object' ? JSON.stringify(body) : body : undefined
})
.then(response => {
// console.log(response);
  if (response.status == 401) return window.location.href = "/login";
  return readBodyResponse(response);
}).catch(er => {
  if (er.response && er.response.status == 404) throw er.data;
  if (er.response && er.response.status == 503) return alert('Не работает сервер Честного знака');
  return errorHandler(er);
});

const fGetOmsToken = () => sOmsTokenUuid ? Promise.resolve(sOmsTokenUuid) : api.get("/auth/key")
.then(oSault => CadesPluginer.signMessage(oSault.data, oSelectedCert)
.then(sSigned => api.post("/auth/simpleSignIn/" + sOmsConnUuid, { ...oSault, data: sSigned })))
.then(oOmsResponse => oOmsHeaders.clientToken = sOmsTokenUuid = oOmsResponse.token);

const api = {
  get: sUrl => fFetch('GET', sBaseUrl + sUrl),
  post: (sUrl, oBody) => fFetch('POST', sBaseUrl + sUrl, oBody),
  postV4: (sUrl, oBody) => fFetch('POST', sBaseUrlV4 + sUrl, oBody),
  fetch: (sMethod, sUrl, oBody) => fFetch(sMethod, sBaseUrl + sUrl, oBody),
  setTokenJwt: sJwt => oHeaders.Authorization = 'Bearer ' + sJwt,
  setSelectedCert: oCert => oSelectedCert = oCert,
  getDb: sUrl => fFetch('GET', sDbBaseUrl + sUrl, null, oDbHeaders),
  postDb: (sUrl, oBody) => fFetch('POST', sDbBaseUrl + sUrl, oBody, oDbHeaders),
  getOms: sUrl => fGetOmsToken().then(() => fFetch('GET', sOmsBaseUrl + sUrl, null, oOmsHeaders)),
  postOms: (sUrl, sBody) => fGetOmsToken()
  .then(() => CadesUtils.signMessage(fConv2b64(sBody), oSelectedCert, true))
  .then(sSigned => oOmsHeaders['X-Signature'] = sSigned.replace(/\r\n/g, ''))
  .then(() => fFetch('POST', sOmsBaseUrl + sUrl, sBody, oOmsHeaders))
}

export default api;

export default {
    cisStatus: {
        EMITTED: 'Выпущен',
        APPLIED: 'Нанесён',
        INTRODUCED: 'В обороте',
        WRITTEN_OFF: 'Списан',
        RETIRED: 'Выбыл',
        // DISAGGREGATION: 'Расформирован'
    },
    gtin: {
        '04670142890041': '04670142890041 - 19 л.',
        '04670142890034': '04670142890034 - 5 л.',
        '04670142890027': '04670142890027 - 0,5 л.',
    },
    certificate_types: {
        CONFORMITY_CERTIFICATE: 'Сертификат соответствия',
        CONFORMITY_DECLARATION: 'Декларация о соответствии',
        STATE_REGISTRATION_CERTIFICATE: 'Свидетельство о государственной регистрации'
    },
    actions: {
        RETAIL: 'Розничная продажа',
        OWN_USE: 'Использование для собственных нужд',
        DESTRUCTION: 'Уничтожение',
        DONATION: 'Безвозмездная передача',
        EXPIRATION: 'Истечение срока годности',
        // KM_DESTROYED: 'Уничтожение',
        // KM_LOST: 'Утрата',
        // KM_SPOILED: 'Испорчен',
        LOSS: 'Утрата',
        OTHER: 'Прочее',
        PRODUCTION_USE: 'Использование для производственных целей',
        STATE_CONTRACT: 'Продажа по государственному (муниципальному) контракту',
        STATE_SECRET: 'Продажа по сделке с государственной тайной',
        UTILIZATION: 'Утилизация',
        VENDING: 'Продажа через вендинговый аппарат'
    },
    documentTypes: {
        RECEIPT: 'Кассовый чек',
        SALES_RECEIPT: 'Товарный чек',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        CUSTOMS_DECLARATION: 'Таможенная декларация',
        UTD: 'Универсальный передаточный документ',
        DESTRUCTION_ACT: 'Акт уничтожения (утраты/утилизации)',
        OTHER: 'Прочее'
    },
    buyers: [
        { inn: '5610058025', name: 'Газпром добыча Оренбург' },
        { inn: '7709769582', name: 'Газпром недра' },
        { inn: '5003028148', name: 'Газпром газобезопасность' },
        { inn: '7728262893', name: 'Газпромтранс' },
        { inn: '5612002437', name: 'Автоколонна №1825' },
        { inn: '5610057511', name: 'Гимназия №4' },
        { inn: '5611030632', name: 'Инженерный центр' },
        { inn: '7736035485', name: 'СОГАЗ' },
        { inn: '5610087812', name: 'Полимер' },
        { inn: '5609027713', name: 'СервисЭнергоГаз' },
        { inn: '5610132751', name: 'Энергокомпания' },
        { inn: '5611028383', name: 'Капремстрой' },
        { inn: '5610221063', name: 'ОРЕНГАН' },
        { inn: '5609184547', name: 'Термотехника' },
        { inn: '5610152807', name: 'Союз общественности МАУДО ДЭБЦ' },
        { inn: '5611081210', name: 'Промавтоматика' },
        { inn: '5610062254', name: 'АНО ФК Оренбург' },
        { inn: '5609047798', name: 'МОО ПС МАУДО ЦДТ' },
        { inn: '1102054991', name: 'Газпром переработка' },
        { inn: '5610059220', name: 'Стоматологическая поликлиника Ростошь' },
        { inn: '5610065008', name: 'Клиника промышленной медицины' },
        { inn: '7736186950', name: 'Газпромэнерго' },
        { inn: '5609029975', name: 'Оренбурггазтранс' },
        { inn: '5609026406', name: 'Озон' },
        { inn: '5406121446', name: 'Компания БКС' },
        { inn: '7702211710', name: 'Газпром питание' },
        { inn: '7719002546', name: 'Газпром геотехнологии Оренбург' },
    ]
};

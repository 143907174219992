import CadesUtils from "@avb56/cades-utils";
import dictionary from "./dictionary";
import api from "./api";
import oConf from './conf';

const { sOmsUuid } = oConf[process.env.REACT_APP_TEST ? 'oDev' : 'oProd'];
const utf8_to_b64 = str => window.btoa(unescape(encodeURIComponent(str)));
const b64_to_utf8 = str => decodeURIComponent(escape(window.atob(str)));

export default async function codesActions(actionName, selectionModel, formData, user) {
    // return console.log({actionName, selectionModel, formData, user});
    const fSign = (sType, sCodeName) => {
        const oDocumentBody = {
            ...formData.once, products: selectionModel.map(code => ({
                ...formData.multi,
                [sCodeName]: code.replace(/("|\\)/g, '\$1')
            }))
        };

        // return console.log(oDocumentBody, JSON.stringify(oDocumentBody, '', 2));
        const sDocumentInBase64 = utf8_to_b64(JSON.stringify(oDocumentBody));
        return CadesUtils.signMessage(sDocumentInBase64, user.oCertInfo.cert, true)
        .then(sSigned => api.post("/lk/documents/create?pg=water", {
            document_format: 'MANUAL',
            product_document: sDocumentInBase64,
            type: sType,
            signature: sSigned.replace(/\r\n/g, '')
        }))
        .then(guid => alert('Создан документ ' + guid), alert);
    }

    switch (actionName) {

        case 'printCodes':
            // return console.log(selectionModel, formData.once.production_date);
            const printDate = formData.once.production_date || formData.once.action_date;

            // return db.post('_bulk_get', { docs: selectionModel.map(id => ({ id })) })
            return api.postDb('_bulk_get', { docs: selectionModel.map(id => ({ id })) })
            .then(oResp => oResp.results.map(oCis => oCis.id + '\u001d' + oCis.docs[0].ok.hash))
            .then(aPrintCodes => fetch('http://localhost/codes/print', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(aPrintCodes.concat(printDate))
            }))
            .then(response => response.text())
            .then(res => {
                console.log(JSON.stringify(res, '', 2));
            }).catch(alert);

        case 'utilisationSign':
            return api.postDb('_bulk_get', { docs: selectionModel.map(id => ({ id })) })
            .then(oResp => oResp.results.map(oCis => oCis.id + '\u001d' + oCis.docs[0].ok.hash))
            .then(aFullCodes => api.postOms('/utilisation?omsId=' + sOmsUuid, {
                productGroup: "water",
                sntins: aFullCodes,
                // sntins: selectionModel.map(sCis => sCis + '\u001d93dGVz'),
                attributes: {
                    productionDate: formData.once.production_date,
                    expirationDate: formData.once.expiration_date
                }
            }))
            .then(oResponce => alert('Создан документ ' + oResponce.reportId), alert);

        case 'introduseSign': return fSign('LP_INTRODUCE_GOODS', 'uit_code');

        case 'retireSign': return fSign('LK_RECEIPT', 'cis');
        
        // default: fileMaker(actionName,  selectionModel, formData);
        default: 
            return api.postDb('_bulk_get', { docs: selectionModel.map(id => ({ id })) })
            .then(oResp => oResp.results.map(oCis => oCis.id + '\u001d' + oCis.docs[0].ok.hash))
            .then(aFullCodes => {
                const link = document.createElement('a');
                const blob = new Blob([aFullCodes.join('\n')], { type: 'text/plain' });
                link.download = 'Коды (' + selectionModel.length + ' шт.).csv';
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
            });
    }
}

function fileMaker(actionName, selectionModel, formData) {
    let head, fill,	link = document.createElement('a');

	switch (actionName) {
        case 'introduseFile':
            const { participant_inn, producer_inn, owner_inn, production_date } = formData.once;
            const { tnved_code = '', certificate_document_data = [], licences = [] } = formData.multi;
            const { certificate_type = '', certificate_number = '', certificate_date = '', well_number = '' } = certificate_document_data[0] || {};
            const { licence_number = '', licence_date = '' } = licences[0] || {};
            const certificateType = dictionary.certificate_types[certificate_type];
            const wellNumber = /,/.test(well_number) ? `"${well_number}"` : well_number;

            link.download = 'Ввод в оборот (' + selectionModel.length + ' шт.).csv';
            fill = `",,${production_date},${tnved_code},${certificateType},${certificate_number},${certificate_date},${wellNumber},${licence_number},${licence_date},`;
            head = `ИНН участника оборота,ИНН производителя,ИНН собственника,Дата производства,Тип производственного заказа,Версия
${participant_inn},${producer_inn},${owner_inn},${production_date},Собственное производство,6
Параметры товаров
КИ,КИТУ,Дата производства,Код ТН ВЭД ЕАС товара,Вид документа подтверждающего соответствие,Номер документа подтверждающего соответствие,Дата документа подтверждающего соответствие,Номер скважины,Номера лицензий на пользование недрами,Дата выдачи лицензии,Идентификатор ВСД
`;
        break;

        case 'retireFile':
            const { inn, buyer_inn = '', action, action_date, document_type, document_number, document_date, primary_document_custom_name = '' } = formData.once;
            const { product_cost = '' } = formData.multi || {};
            const actionRu = dictionary.actions[action];
            const documentType = dictionary.documentTypes[document_type];

            link.download = 'Вывод из оборота (' + selectionModel.length + ' шт.).csv';
            fill = '",' + product_cost + ',,,,';
            head = `ИНН участника оборота,ИНН покупателя,Причина вывода из оборота,Другая причина вывода из оборота,Дата вывода из оборота,Тип первичного документа,Номер первичного документа,Дата первичного документа,Наименование первичного документа,Идентификатор ФИАС,Идентификатор госконтракта,Версия
${inn},${buyer_inn},${actionRu},,${action_date},${documentType},${document_number},${document_date},${primary_document_custom_name},,,7
Параметры товаров
КИ,Цена за единицу,Тип первичного документа,Номер первичного документа,Дата первичного документа,Наименование первичного документа
`;
        break;

        case 'utilisationFile':
            link.download = 'Нанесение кодов (' + selectionModel.length + ' шт.).csv';
            fill = '"';
            head = '';
        break;
    }

    const codes = selectionModel.map(code => '"' + code.replace(/"/g, '""') + fill);
    const blob = new Blob([head + codes.join('\n')], { type: 'text/plain' });
	link.href = URL.createObjectURL(blob);
	link.click();
	URL.revokeObjectURL(link.href);
}

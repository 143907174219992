import React, { useState, useEffect } from "react";
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { useOutletContext, useNavigate } from "react-router-dom";
import api from "./api";
import Box from '@mui/material/Box';
import dictionary from "./dictionary";

const cellsFromObj = obj => Object.entries(obj).map(([key, value]) => [key, 0]);
const colsFromObj = obj => Object.entries({ name: 'Продукция', ...obj, sum: 'Всего' })
.map(([key, val]) => ({ field: key, headerName: val, width: key === 'name' ? 210 : 150 }))
.filter(oRow => oRow.field != 'RETIRED');

const fInc = prop => prop ? ++prop : 1;

export function Stat() {
    const { setUrlOpts, user } = useOutletContext();
    const rowsFromObj = obj => Object.entries({ ...obj, total: 'Итого' })
    .map(([key, val]) => ({
        id: key, name: val, sum: 0, ...Object.fromEntries(cellsFromObj(dictionary.cisStatus))
    }));
    const [loading, setLoading] = useState();
    const [statisticData, setStatisticData] = useState(rowsFromObj(dictionary.gtin));
    const navigate = useNavigate();
    const toFilter = cell => {
        const cisStatus = cell.field in dictionary.cisStatus && cell.field;
        const gtin = cell.id in dictionary.gtin && cell.id;

        if (!cisStatus || !gtin) return;
        setUrlOpts({ cisStatus, gtin, limit: 1000 });
        navigate("/codes");
    }

    const getCises = aLastRes => {
        const oLastCis = aLastRes && aLastRes.at(-1);
        return api.postV4('/cises/search', {
          filter: {
            productGroups: ["water"],
            states: [
              { status: 'EMITTED' },
              { status: 'APPLIED' },
              { status: 'INTRODUCED' },
              { status: 'WRITTEN_OFF' },
            ],
            producerInns: [user.oTokenInfo.inn]
          },
          pagination: {
            perPage: 1000,
            lastEmissionDate: oLastCis && oLastCis.emissionDate,
            sgtin: oLastCis && oLastCis.sgtin,
          }
        }).then(oRes => {
          // console.log(oRes);
          const aRes = (aLastRes || []).concat(oRes.result);
          return oRes.isLastPage ? aRes : getCises(aRes);
        });
    };
    
    const fCalcCises = () => getCises().then(aCises => {
        // console.log(aCises);
        const oStatRows = aCises.reduce((oRes, oRow) => {
            if (oRow.ownerInn != user.oTokenInfo.inn) return oRes;
            if (!oRes[oRow.gtin]) oRes[oRow.gtin] = {};
            oRes[oRow.gtin][oRow.status] = fInc(oRes[oRow.gtin][oRow.status]);
            oRes.total[oRow.status] = fInc(oRes.total[oRow.status]);
            oRes.total[oRow.gtin] = fInc(oRes.total[oRow.gtin]);
            // oRes.total.total++;
            return oRes;
        }, { total: { total: 0 } });
        oStatRows.total.total = aCises.length;
        return oStatRows;
    });

    useEffect(() => {
        setLoading(true);
        fCalcCises().then(oCalcCises => setStatisticData(prev => {
            // console.log(oCalcCises);
            const will = prev.slice();
            for (let sGtin in oCalcCises) {
                const oCalcRow = oCalcCises[sGtin];
                const oCisesRow = will.find(oRow => oRow.id === sGtin);
                for (let sStatus in oCalcRow) oCisesRow[sStatus] = oCalcRow[sStatus];
                oCisesRow.sum = oCalcCises.total[sGtin];
            };
            setLoading(false);
            return will;
        }))
    }, []);

    return (
        <Box sx={{ height: '50vh', m: 1, cursor: 'default' }}>
            <DataGrid
                rows={statisticData}
                columns={colsFromObj(dictionary.cisStatus)}
                aria-label='Total'
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                hideFooter
                onCellClick={toFilter}
                loading={loading}
            />
        </Box>
    );
}

import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PrintIcon from '@mui/icons-material/Print';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function CodesEmitted(props) {
    const { selectionModel, fActions, children } = props;
    const [production_date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [expiration_date, setExpDate] = useState(calcExpire(production_date));
    const fActionsHandler = ev => fActions(ev.target, { once : { production_date, expiration_date } });

    function calcExpire(dateProd) {
        const dateExp = new Date(dateProd);
        dateExp.setMonth(new Date(dateProd).getMonth() + 3);
        return dateExp.toISOString().slice(0, 10);
    }

    function handleChangeDate(ev) {
        const newDate = ev.target.value;
        if (Date.now() < new Date(newDate)) return alert('Дата производства продукции не должна быть позднее текущей даты.');
        setDate(newDate);
        setExpDate(calcExpire(newDate));
    }

    return <>
        <DialogTitle
            id="draggable-dialog-title"
            sx={{ pr: 8, cursor: 'move', width: 500 }}
        >
            Отчёт о нанесении кодов
        </DialogTitle>
        <DialogContent dividers>
            {children}
            <Grid container spacing={3} alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={6}>
                    <TextField
                        type="date"
                        variant="outlined"
                        label='Дата производства продукции'
                        fullWidth
                        value={production_date}
                        onChange={handleChangeDate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="date"
                        variant="outlined"
                        label='Дата истечения срока годности'
                        fullWidth
                        value={expiration_date}
                        onChange={ev => setExpDate(ev.target.value)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 1 }} onClick={fActionsHandler}>
            <Button variant="contained" name='printCodes' title={'Печать'} sx={{ mr: 2 }}>
                <PrintIcon />
            </Button>
            <FormatPaintIcon sx={{ mr: 1 }} />{selectionModel.length} код(а/ов)
            <Button variant="contained" name='utilisationFile' sx={{ mx: 1 }}>Файл</Button>
            <Button variant="contained" name='utilisationSign'>Подписать и отправить</Button>
        </DialogActions>
    </>
}
